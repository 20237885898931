import React from 'react';
import MenuInner from './parts/MenuInner';
import Footer from "./parts/Footer";
import ContentItem from './ContentItem';

const Portfolio = ()=>{

    document.title = "Portfolio | Gabriel Guerra Full Stack Developer";

    return (
        <div>
            <MenuInner/>
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1>PORTFOLIO</h1>

                            <div className="row">
                                <div className="col-md-5">
                                    <h3>WORDPRESS</h3>

                                    <ContentItem
                                        url="http://www.geosystem.com.br/"
                                        title="GeoSystem"
                                        text="Website developed using Wordpress. All main areas are updated by the client through control panel."
                                    />

                                    <ContentItem
                                        url="http://www.culturainglesa-ba.com.br/"
                                        title="Cultura Inglesa"
                                        text="Website developed using Wordpress. All main areas are updated by the
                                        client through control panel."
                                    />

                                    <ContentItem
                                        url="https://www.agenciapulse.com.br"
                                        title="Pulse"
                                        text="Full portfolio plus landing pages based in a Wordpress custom theme."
                                    />

                                    <ContentItem
                                        url="#"
                                        title="Elsevier"
                                        text="Wordpress Hotsite for a marketing campaign."
                                    />

                                    <ContentItem
                                        url="http://www.institutoprowork.com.br/"
                                        title="Prowork"
                                        text="Website developed using Wordpress. All main areas are updated by the client through control panel."
                                    />

                                    <ContentItem
                                        url="http://danviniimoveis.com.br/"
                                        title="Danvini"
                                        text="Premium houses for selling in a beautiful Wordpress catalog."
                                    />

                                    <ContentItem
                                        url="https://www.nandagama.com/"
                                        title="Nanda Gama"
                                        text="Website developed using Wordpress. All main areas are updated by the client through control panel."
                                    />

                                </div>

                                <div className="col-md-5 offset-md-1">
                                    <h3>WEB APPS</h3>

                                    <ContentItem
                                        url="#"
                                        title="Briefings System"
                                        text="Web app created to make the information gathering (aka briefing) process
                                        faster, easier and reliable. Basically is possible to create a template for
                                        common questions and send to the user to answer. The briefing is answered
                                        and goes to a dashboard to be treated by the responsible. Is on the road map
                                        to integrate this app with a task management system."
                                    />

                                    <ContentItem
                                        url="#"
                                        title="Prospects System"
                                        text="App for leads administration with automated invoice generation. The user
                                        is capable of add new templates for invoices and save a lot of time in
                                        prospective tasks."
                                    />

                                    <ContentItem
                                        url="#"
                                        title="Alcancy"
                                        text="Backoffice responsive Web App for administrative tasks support.
                                        Features includes REST endpoints for AngularJs App, token based
                                        authentication system and customers handling. Reduced the volume of
                                        paper in candidate's applications dramatically."
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Portfolio;