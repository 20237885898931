import React from 'react';
import {Link} from 'react-router-dom';

const MenuInner = ()=>{

    return(
        <div className="home-menu">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-3 mobile-center">
                        <a href="/" className="home">
                            <h1 className="logo">Gabriel Guerra</h1>
                            <span className="sublogo">DEVELOPER</span>
                        </a>
                    </div>
                    <div className="col-12 col-md-12 col-lg-7 mobile-center">
                        <ul className="nav justify-content-center mobile-center">
                            <li className="nav-item"><Link className="nav-link" to={"/about"} title="About me">ABOUT</Link></li>
                            <li className="nav-item"><Link className="nav-link" to={"/portfolio"} title="Portfolio">PORTFOLIO</Link></li>
                            <li className="nav-item"><Link className="nav-link" to={"/articles"} title="Articles on Medium">ARTICLES</Link></li>
                            <li className="nav-item"><Link className="nav-link" to={"/projects"} title="Projects on GitHub">PROJECTS</Link></li>
                            <li className="nav-item"><Link className="nav-link" to={"/contact"} title="Contact">CONTACT</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-2 d-none d-lg-block">
                        <div className="menu-icons ">
                            <a href="https://github.com/ogabrielguerra" target="_blank" rel="noopener noreferrer"><i className=" fab fa-github"></i></a>
                            <a href="https://medium.com/@heygabriel" target="_blank" rel="noopener noreferrer"><i className=" fab fa-medium-m"></i></a>
                            <a href="https://www.linkedin.com/in/gabriel-guerra/" target="_blank" rel="noopener noreferrer"><i className=" fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MenuInner;