import React, {useState, useEffect} from 'react';
import MenuInner from './parts/MenuInner';
import Footer from "./parts/Footer";
import ContentItem from './ContentItem';
import axios from 'axios';
import Loading from './Loading';
import xml2js from 'xml2js';
import XMLData from './data/medium_feed.xml';

const Articles = ()=>{
    
        document.title = "Articles | Gabriel Guerra Full Stack Developer";
        const [jsonFeedData, setJsonFeedData] = useState(null);
        let rows;

        useEffect(() => {
            axios.get(XMLData, {"Content-Type": "application/xml; charset=utf-8"})
            .then((response) => {
                let parseString = xml2js.parseString;
                let xml = response.data
                parseString(xml, function (err, result) {
                    let articles = result.rss.channel[0].item;
                    setJsonFeedData(articles)
                });
            });
        }, []);
        
        let keyCount = 0;

        const cleanDescription = (description)=>{
            let temp = description.replace(/(<([^>]+)>)/gi, "");
            temp = temp.replaceAll('Continue reading on Medium »', '...');
            temp = temp.replaceAll('Continue reading on Level Up Coding »', '...');
            temp = temp.replaceAll('&#x2026;', '');
            temp = temp.replaceAll('&#x2019;', '\'');
            temp = temp.replaceAll('&apos;', '\'')
            return temp;
        }

        if(jsonFeedData){

            rows = jsonFeedData.map((item)=>{

                keyCount++; 
                let description = cleanDescription(item.description.toString())

                return (
                    <ContentItem
                                key={keyCount}
                                url={item.link}
                                title={item.title}
                                text={description} />
                )
            });
        }else{
            rows = <Loading/>;
        }
        
        return (    
            <div>
                <MenuInner/>
                <section className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                                <h1>ARTICLES</h1>
                                {rows}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        )
    
}

export default Articles;