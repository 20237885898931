import React from 'react';
import Menu from './parts/Menu';
import Footer from "./parts/Footer";

const Home = ()=>{

    document.title = "Gabriel Guerra Full Stack Developer";

    return (
        <div>
            <div className="home-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12 offset-0 mobile-center">
                            <div className="title">
                                <h1>GABRIEL GUERRA</h1>
                                <span>DEVELOPER</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Menu/>

            <section className="content">
                <div className = "container" >
                    <div className = "row" >
                        <div className = "col-md-6 col-sm-12 col-12" >
                            <h1> BACKEND </h1>
                                <p>To bring well written applications to life, that's the mission. PHP or Python, choose your weapon.</p>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <h1>FRONTEND</h1>
                            <p>Modern concepts of web development using CSS, Bootstrap, Javascript Vanilla and frameworks like React and
                                jQuery.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-12">
                            <h1>COMMUNICATION SKILLS</h1>
                            <p>Due to my early background, I'm fully capable to speak designer's language, eliminating most of that
                                noise between different teams. In the same way, I can talk with clients, attend meetings and prepare
                                explanatory presentations.</p>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                            <h1>EXPERIENCE</h1>
                            <p>Programming professionally since 2006, I have solid experience with PHP, OOP and frameworks like
                                Laravel.</p>
                            <a className="btn btn-info btn-lg" href="http://bit.ly/2Fy8Y3o" rel="noopener noreferrer" target="_blank"><i
                                className="fa fa-file-pdf"></i> DOWNLOAD MY CV</a>
                        </div>
                    </div>
                    </div>
            </section>
            <Footer/>
        </div>
    );
}

export default Home;