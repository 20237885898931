import React from 'react';
import MenuInner from './parts/MenuInner';
import Footer from "./parts/Footer";
import ContentItem from './ContentItem';

const Projects = ()=>{
        
    document.title = "Projects | Gabriel Guerra Full Stack Developer";
    return (
        <div>
            <MenuInner/>
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1>PROJECTS</h1>

                            <ContentItem
                                url="http://vacuum.gabrielguerra.me/"
                                title="Vacuum [PHP]"
                                text="Db-less website framework based in json files, delivering Wordpress main features like tags, categories, media and thumbnails."
                            />

                            <ContentItem
                                url="https://github.com/ogabrielguerra/phpCrudCore"
                                title="CrudCore [PHP]"
                                text="A set of PHP Classes to do CRUD operations, with database table mapper/loader."
                            />

                            <ContentItem
                                url="https://github.com/ogabrielguerra/wordpress-to-static-files"
                                title="Wordpress to static [Python]"
                                text="Sometimes you need more control over what to include in the queue or maybe you don't have access full access to the database."
                            />

                            <ContentItem
                                url="https://github.com/ogabrielguerra/sendamessage"
                                title="Send a Message [Javascript]"
                                text="Sending form data through AJAX in json format easily."
                            />

                        </div>

                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Projects;