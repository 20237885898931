import React from 'react';
import MenuInner from './parts/MenuInner';
import Footer from "./parts/Footer";

const Contact = ()=>{

    document.title = "Contact | Gabriel Guerra Full Stack Developer";

    return (
        <div>
            <MenuInner/>
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1>CONTACT</h1>
                            <p>Hey! Drop me a line at gabrielguerra@gmail.com.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default Contact;