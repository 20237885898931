import React from 'react';

const ContentItem = (props)=>{
    return(
        <div className="contentItem">
            <h2><a 
                    href={props.url} 
                    title={props.title} 
                    rel="noopener noreferrer" 
                    target="_blank">{props.title}</a></h2>
            <p>{props.text}</p>
            <hr/>
        </div>
    )
}

export default ContentItem;