import React from 'react';
class Footer extends React.Component{

    render(){

        return (
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="menu-icons text-center">
                                <a href="https://github.com/ogabrielguerra" target="_blank" rel="noopener noreferrer" title="GitHub"><i className=" fab fa-github"></i></a>
                                <a href="https://medium.com/@heygabriel" target="_blank" rel="noopener noreferrer" title="Medium"><i className=" fab fa-medium-m"></i></a>
                                <a href="https://www.linkedin.com/in/gabriel-guerra/" target="_blank" rel="noopener noreferrer" title="Linkedin"><i className=" fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;