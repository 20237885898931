import React from 'react';
import MenuInner from './parts/MenuInner';
import Footer from "./parts/Footer";

const About = ()=>{

    document.title = "About | Gabriel Guerra Full Stack Developer";

    return (
        <div>
            <MenuInner/>
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 col-12">
                            <h1>ABOUT</h1>
                            <p>I started my coding journey in 2006. Being a web designer for a couple of years, I decided it was the right time to take the next step, so I began to try PHP.</p>
                            <p>At the time PHP opened a wide spectrum of possibilities in terms of tools and applications like Wordpress, Moodle, PHPCollab and many others. In the meantime, I was very intrigued with
                                ActionScript and how we could make Adobe Flash (RIP) applications dynamic with XML and simple http requests. What an exciting ride!</p>
                            <p>The web was changing fast. Everything was quite seducing and challenging at the same time. So, trying to to get into the game I cofounded Pulse - a digital agency, focused in
                                developing websites and web based systems.</p>
                            <p>At Pulse I tried many platforms and frameworks. In fact, I've started my own PHP framework, which the core is
                                still available in GitHub. Basically is <strong>a simple database mapper that turns your row into an object</strong> making things like CRUD a little bit easier and adding a layer of
                                automation.</p>
                            <h2>What am I doing now?</h2>
                            <p>Nowadays I work for a big telecom player as Software Engineer. Tough I still do code as a Full Stack Engineer, as a freelancer and in personal projects as well.</p>
                            <p>If I'm not coding, probably you'll find me playing guitar, recording and producing music or reading, not necessarily in that order.</p>

                            <p>More? You can reach me at <a href="https://www.linkedin.com/in/gabriel-guerra/" rel="noopener noreferrer" target="_blank">LinkedIn</a> or
                                <a href="contact">drop me a line by email</a> if you prefer.</p>
                        </div>

                        <div className="col-md-4 offset-md-1 col-sm-12 col-12">
                            <h1>EXPERIENCE</h1>
                            <div className="badges">
                                <i className="fab fa-laravel"></i> <i className="fab fa-php"></i> <i className="fab fa-bootstrap"></i>
                                <i className="fab fa-python"></i> <i className="fab fa-js"></i> <i className="fab fa-react"></i> <i className="fab fa-gulp"></i>
                                <i className="fab fa-wordpress"></i> <i className="fa fa-database"></i> <i className="fa fa-flask"></i>
                                <i className="fab fa-npm"></i> <i className="fab fa-git"></i> <i className="fab fa-css3-alt"></i> <i className="fab fa-html5"></i>
                            </div>
                            <p>Programming professionally since 2006, I have solid experience with PHP, OOP and frameworks like Laravel and Flask (Python).</p>
                            <a className="btn btn-info btn-lg" href="http://bit.ly/2Fy8Y3o" rel="noopener noreferrer" target="_blank"><i className="fa fa-file-pdf" ></i> DOWNLOAD MY CV</a>
                        </div>

                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    )
}

export default About;