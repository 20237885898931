import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Articles from './Articles';
import Projects from './Projects';
import Contact from './Contact';

const Routing = ()=>{
        return (
            <BrowserRouter>
                <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path={"/about"} element={<About/>}/>
                    <Route path={"/portfolio"} element={<Portfolio/>} />
                    <Route path={"/articles"} element={<Articles/>} />
                    <Route path={"/projects"} element={<Projects/>} />
                    <Route path={"/contact"} element={<Contact/>} /> 
                </Routes>
            </BrowserRouter>
        );
}

export default Routing;